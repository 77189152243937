import React, { useState, useEffect } from "react";
import Head from "next/head";
import DirectServiceLandingPage from "~/components/DirectServiceLandingPage";

const Home = (props) => {
    const [logoPartner, setLogoPartner] = useState("");

    useEffect(() => {
        props.utmSource && window.sessionStorage.setItem("utm_source", props.utmSource);
        setLogoPartner(sessionStorage.getItem("utm_source"));
    }, [logoPartner]);

    return (
        <>
            <Head>
                <link rel="stylesheet" type="text/css" href="/css/outside.css" />
                <link rel="stylesheet" type="text/css" href="/css/home.css" />
                <title>Fotógrafos e serviços de fotografia profissional - Fotop Serviços</title>
            </Head>

            <DirectServiceLandingPage />
        </>
    );
};

Home.getInitialProps = ({ query }) => ({ utmSource: query.utm_source });

export default Home;
